body {
  margin: 0;
  /* font-family: Helvetica, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  position: relative;
}

#root::after {
  content: '';
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  background-image: linear-gradient(
      to bottom,
      rgba(245, 246, 252, 0.3),
      rgba(255, 255, 255, 1) 40vw
    ),
    url('/background.jpeg');
  background-position: top;
  background-size: 100vw auto;
  background-repeat: no-repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pac-target-input:focus {
  border: none;
  outline: none;
}

.contains-autocomplete:focus-within {
  border: 2px solid #520d9b;
}

input:focus {
  border: 2px solid #520d9b;
  outline: none;
}

input[type='text'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

blockquote {
  font-family: 'Metropolis';
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  margin: 0.25em 0;
  padding: 0.35em 40px;
  line-height: 1.45;
  position: relative;
  color: #383838;
}

blockquote:before {
  display: block;
  padding-left: 10px;
  content: '\201C';
  font-size: 100px;
  position: absolute;
  left: -20px;
  top: -40px;
  color: #520d9b;
}

blockquote cite {
  font-family: 'Metropolis';
  font-style: normal;
  color: #999999;
  font-size: 14px;
  display: block;
  margin-top: 5px;
}

blockquote cite:before {
  content: '\2014 \2009';
}
